import React from "react";
import "../styles/Skills.css";

function Skills() {
    return(
        <div>
            
        </div>
    );
}

export default Skills;